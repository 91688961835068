<template>
	<v-dialog v-model="dialog" max-width="800">
		<v-card>
			<v-toolbar dark color="primary">
				<v-toolbar-title>{{ transaction.status.name }} {{ transaction.transactionType.name }} </v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn icon dark @click="closeModal">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>

			<v-card-text>
				<!-- Display transaction details here -->
				<div v-if="transaction">
						<v-row>
							<v-col cols="6">
								<strong>Amount:</strong> K{{ transaction.amount }}
							</v-col>
							<v-col cols="6">
								<strong>Payable:</strong>
                                <strong><span style="color: red;"> K{{ transaction.amountPlusInterest }} </span>   </strong>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
                                <strong>Service Fee/Charge:</strong> K{{ transaction.chargeAmount }}
							</v-col>
							<v-col cols="6">
								<strong>Disbursed:</strong>   
                                <strong><span style="color: green;"> K{{ transaction.disburedAmount }} </span>   </strong>                          								
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<strong>Charge Percentage:</strong>
								{{ transaction.chargePercentage }}%
							</v-col>
							<v-col cols="6">
								<strong>Interest Percentage:</strong>
								{{ transaction.interestPercentage }}%
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<strong>Customer Account:</strong>
								{{ transaction.customerAccount }}
							</v-col>
							<v-col cols="6">
								<strong>Status:</strong>
                                <span style="color: green;"> {{ transaction.status.name }}</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<strong>Transaction Date:</strong> {{ transaction.createdAt }}
							</v-col>
							<v-col cols="6">
								<strong>Maturity Date:</strong> {{ transaction.maturityDate }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<strong>Tenure Name:</strong> {{ transaction.tenure.name }}
							</v-col>
							<v-col cols="6">
								<strong>Tenure Days:</strong>
								{{ transaction.tenure.days }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<strong>Name:</strong>
								{{ transaction.customer.firstName }} {{ transaction.customer.lastName }} 
							</v-col>
							<v-col cols="6">
								<strong>Company:</strong> {{ transaction.customer.user.company.name }}
							</v-col>
						</v-row>
					<!-- Add more sections for other objects -->
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		props: {
			transaction: Object, // Pass the transaction data as a prop
		},
		data() {
			return {
				dialog: false,
			};
		},
		methods: {
			openModal() {
				this.dialog = true;
			},
			closeModal() {
				this.dialog = false;
			},
		},
	};
</script>
